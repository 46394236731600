// @import '~@lsfe/d/scss/mobile';
// @import '~@lsfe/d/scss/font';

.mt-font {
    font-family: MTfin-Regular;
}
html,
body {
    overflow-x: auto;
}
