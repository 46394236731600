.swiper-pagination {
  // text-align: left;
}
.swiper-container {
  margin: 0;
}
.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  opacity: 0.6;
  background: #FFFFFF;
}
.swiper-pagination-clickable {
  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    opacity: 0.6;
    background: #FFFFFF;
  }
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 7px;
}

.swiper-pagination-bullet-active {
  background: #FFD100;
  opacity: 1;
}
.swiper-pagination-clickable {
  .swiper-pagination-bullet-active {
    background: #FFD100;
    opacity: 1;
  }
}

.newsContent {
  .swiper-pagination-bullet {
    background: #D8D8D8;
  }
  .swiper-pagination-clickable {
    .swiper-pagination-bullet {
      background: #D8D8D8;
    }
  }
  .swiper-pagination-bullet-active {
    background: #FFD100;
    opacity: 1;
  }
  .swiper-pagination-clickable {
    .swiper-pagination-bullet-active {
      background: #FFD100;
      opacity: 1;
    }
  }
}

.swiper-button-next,
.swiper-button-prev {
  width: 50px;
  height: 50px;
  background: url('../../assets/images/icon-left.png') no-repeat;
  background-size: 10px 30px;
  cursor: pointer;
  z-index: 8;
}
.swiper-button-next {
  transform: scaleX(-1);
}